import React from "react";
import parse from "html-react-parser";
import { Wrapper } from "./index.styled";
import { Box } from "@chakra-ui/react";

interface Props {
  text: string;
}

export default function RichText(props: Props) {
  return <Wrapper>{props.text && parse(props.text)}</Wrapper>;
}
