import React from "react";
import { Box, Button, Flex, Grid, GridItem } from "@chakra-ui/react";
import styled from "styled-components";
import TitleDefault from "../../TitleDefault";
import IconArrowRepresentantes from "../../../assets/icons/icon-arrow-representantes.svg";
import IconLojas from "../../../assets/icons/icon-lojas.svg";
import IconRepresentantes from "../../../assets/icons/icon-representantes.svg";
import { useModalRepresentante } from "../../../provider/modalRepresentantes";

interface Props {
  subtitulo?: string; //Perto de você
  titulo?: string; //Onde encontrar
  btnLojas?: string; //Encontrar lojas
  btnRepresentantes?: string; //Encontrar representantes
}

const WrapperFlex = styled(Flex)`
  p {
    margin-bottom: 0;
  }
  h2 {
    font-size: 2.3em;
    padding-right: 0;
    @media screen and (max-width: 768px) {
      font-size: 1.5em;
    }
  }
  hr {
    margin-bottom: 1em;
  }
`;

export default function Representantes({
  subtitulo,
  titulo,
  btnLojas,
  btnRepresentantes,
}: Props) {
  const { setRepresentanteType, setIsOpen } = useModalRepresentante();

  const openRepresentantesModal = (type: "loja" | "representante") => {
    setRepresentanteType(type);
    setIsOpen(true);
  };

  return (
    <WrapperFlex w="full" flexDir="column" py={20}>
      <Flex
        w={["80%", "80%", "90%", "90%", "1170px"]}
        flexDir={["column", "column", "row"]}
        mx="auto"
      >
        <Grid
          templateColumns={["1fr", "1fr", "6fr 5fr 5fr"]}
          gap={[4, 4, 14]}
          w="full"
        >
          <GridItem
            bgColor="white"
            borderRadius="20px"
            boxShadow="base"
            p={[4, 4, 8]}
            bgImage="/bg-brasil.png"
            bgPos="right center"
            bgRepeat="no-repeat"
            bgSize="contain"
          >
            <TitleDefault
              subtitulo={subtitulo ?? "Perto de você"}
              titulo={titulo ?? "titulo"}
            />
            <IconArrowRepresentantes />
          </GridItem>
          <GridItem
            bgColor="white"
            borderRadius="20px"
            boxShadow="base"
            p={8}
            bgGradient="linear( #EFEFF1 0, #EFEFF1 30px, #FFF 30px )"
            textAlign="center"
            alignSelf="center"
          >
            <Flex w="full" justifyContent="center" mt={3}>
              <IconLojas />
            </Flex>
            <Button
              mt={4}
              colorScheme="teal"
              bgColor="#007DC5"
              fontSize="14px"
              borderRadius="12px"
              fontWeight="normal"
              textTransform="uppercase"
              px={6}
              _hover={{ bgColor: "#007DC5" }}
              onClick={() => openRepresentantesModal("loja")}
            >
              {btnLojas ?? "Encontrar lojas"}
            </Button>
          </GridItem>
          <GridItem
            bgColor="white"
            borderRadius="20px"
            boxShadow="base"
            p={8}
            bgGradient="linear( #EFEFF1 0, #EFEFF1 30px, #FFF 30px )"
            textAlign="center"
            alignSelf="center"
          >
            <Flex w="full" justifyContent="center" mt={3}>
              <IconRepresentantes />
            </Flex>
            <Button
              mt={4}
              colorScheme="teal"
              bgColor="#00377B"
              fontSize="14px"
              borderRadius="12px"
              fontWeight="normal"
              textTransform="uppercase"
              px={6}
              _hover={{ bgColor: "#00377B" }}
              onClick={() => openRepresentantesModal("representante")}
            >
              {btnRepresentantes ?? "Encontrar representantes"}
            </Button>
          </GridItem>
        </Grid>
      </Flex>
    </WrapperFlex>
  );
}
