import styled from "styled-components";

export const Wrapper = styled.div`
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #101010;
    font-size: 1.5em;
    :after {
      content: "";
      display: block;
      width: 40px;
      height: 3px;
      background-color: #ed1b2f;
      margin: 8px 0 20px;
    }
  }
  @media (max-width: 768px) {
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 1.1em;
    }
  }
`;
